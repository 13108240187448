import { getFavori } from './store'
import {
  Teinte,
  FOURNISSEUR,
  GROUPE_PRIX_COPRODEX,
  GARANTIE_FRANCE,
  TYPE_DE_FILM,
  NUANCE,
  COLLECTION,
  STRUCTURE,
} from './Teinte'

export const ALL_TEINTES: Teinte[] = [
  {
    index: 1,
    favori: getFavori('1'),
    image: `1-Graubeige.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'graubeige',
    referenceDusage: '02_11_11_000040-11670010_1905-167',
    referenceUtilisee: '02_11_11_000040-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_11_000040-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 2,
    favori: getFavori('2'),
    image: `2-Bleu Outremer - Ultramarinlbau.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu outremer - ultramarinblau',
    referenceDusage: '02_11_51_000026-11670050_0205-167',
    referenceUtilisee: '02_11_51_000026-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_51_000026-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 3,
    favori: getFavori('3'),
    image: `3-Chartwelle Green 49246.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chartwell green 49246',
    referenceDusage: '02_11_61_000029-10110049_246',
    referenceUtilisee: '02_11_61_000029-101100',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_61_000029-101100',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 4,
    favori: getFavori('4'),
    image: `4-Pyrite (Metallic).jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'pyrite (metallic)',
    referenceDusage: '02_12_17_000001-119500',
    referenceUtilisee: '02_12_17_000001-119501',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_17_000001-119501',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['JAUNE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 5,
    favori: getFavori('5'),
    image: `5-Trompet C-32 N Glatt.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'trompet c-32 n glatt',
    referenceDusage: '02_12_71_000019-808300',
    referenceUtilisee: '02_12_76_000019-808302',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_76_000019-808302',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 6,
    favori: getFavori('6'),
    image: `6-Silver D Glatt.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'silver d glatt',
    referenceDusage: '02_12_76_000013-808302',
    referenceUtilisee: '02_12_76_000013-808302',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_76_000013-808302',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 7,
    favori: getFavori('7'),
    image: `7-Silver V 9705.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'silver v 9705',
    referenceDusage: '02_12_76_000014-119501',
    referenceUtilisee: '02_12_76_000014-119501',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_76_000014-119501',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 8,
    favori: getFavori('8'),
    image: `8-Asphalt.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'asphalt',
    referenceDusage: '02_12_76_000015-119501',
    referenceUtilisee: '02_12_76_000015-119501',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_76_000015-119501',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 9,
    favori: getFavori('9'),
    image: `9-Choco Brown 8210.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'choco brown 8210',
    referenceDusage: '02_12_81_000011-116701',
    referenceUtilisee: '02_12_81_000011-116701',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_81_000011-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 10,
    favori: getFavori('10'),
    image: `11-Gris Anthracite Ulti Mat.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'crystal white',
    referenceDusage: '02_12_91_000005-116701',
    referenceUtilisee: '02_20_91_000002-117900',
    equivalentRenolitPx: '02_20_91_000002-117900',
    equivalentRenolitPx2: '02_12_91_000005-116701',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 11,
    favori: getFavori('11'),
    image: `12-Beige Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris anthracite ulti mat',
    referenceDusage: '02_20_71_000001-504700',
    referenceUtilisee: '02_20_71_000001-504700',
    equivalentRenolitPx: '02_20_71_000001-504700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 12,
    favori: getFavori('12'),
    image: `13-Beige.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'beige lisse',
    referenceDusage: '10_1595-083',
    referenceUtilisee: '02_12_11_000028-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_11_000028-808300',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 13,
    favori: getFavori('13'),
    image: `14-Jaune.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'beige',
    referenceDusage: '10_1505-167',
    referenceUtilisee: '456_5056',
    equivalentRenolitPx: '02_20_11_000006-116700',
    equivalentRenolitPx2: '02_12_11_000028-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5056',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 14,
    favori: getFavori('14'),
    image: `15-Oregon 4.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'jaune',
    referenceDusage: '10_8705-167',
    referenceUtilisee: '02_11_11_000062-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_11_000062-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['JAUNE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 15,
    favori: getFavori('15'),
    image: `16-Crown Platin.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'oregon 4',
    referenceDusage: '119_2001-167',
    referenceUtilisee: '9_119_2301-116700',
    equivalentRenolitPx: '9_119_2301-116700',
    equivalentRenolitPx2: '9_119_2001-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['JAUNE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 16,
    favori: getFavori('16'),
    image: `17-Platin Quartz.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'crown platin',
    referenceDusage: '1293_001-195',
    referenceUtilisee: '9_1293_001-119500',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_1293_001-119500',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 17,
    favori: getFavori('17'),
    image: `18-Earl Platin.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'platin quartz',
    referenceDusage: '1293_002-195',
    referenceUtilisee: '9_1293_002-119500',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_1293_002-119500',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 18,
    favori: getFavori('18'),
    image: `19-Bronze Platin 1.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'earl platin',
    referenceDusage: '1293_010-195',
    referenceUtilisee: '9_1293_010-119500',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_1293_010-119500',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 19,
    favori: getFavori('19'),
    image: `20-Aluminium Brush Effect.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bronze platin 1',
    referenceDusage: '1293_714-119501',
    referenceUtilisee: '9_1293_714-119501',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '9_1293_714-119501',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 20,
    favori: getFavori('20'),
    image: `21-Beige Croe.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'aluminium brush effect',
    referenceDusage: '1298_702-119501',
    referenceUtilisee: '9_1298_702-119501',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '9_1298_702-119501',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 21,
    favori: getFavori('21'),
    image: `22-Desert Oak.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'beige crème',
    referenceDusage: '13_7905-167',
    referenceUtilisee: '02_20_11_000001-116700',
    equivalentRenolitPx: '02_20_11_000011-116700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_11_000011-116701',
    equivalentHornschuch: '456_5054',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 22,
    favori: getFavori('22'),
    image: `10-Crystal White.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'desert oak',
    referenceDusage: '2_0057_004-130300',
    referenceUtilisee: '2_0057_004-130300',
    equivalentRenolitPx: '2_0057_004-130300',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 23,
    favori: getFavori('23'),
    image: `23-Mountain Oak.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'mountain oak',
    referenceDusage: '2_0057_005-130300',
    referenceUtilisee: '2_0057_005-130300',
    equivalentRenolitPx: '2_0057_005-130300',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 24,
    favori: getFavori('24'),
    image: `24-Monument Oak.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'monument oak',
    referenceDusage: '2_0057_006-130300',
    referenceUtilisee: '2_0057_006-130300',
    equivalentRenolitPx: '2_0057_006-130300',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 25,
    favori: getFavori('25'),
    image: `25-Chêne des Marais.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne des marais',
    referenceDusage: '205_2089-167',
    referenceUtilisee: '436_2007',
    equivalentRenolitPx: '9_205_2389-116700',
    equivalentRenolitPx2: '9_205_2089-116700',
    equivalentRenolitFx: '9_205_2789-116701',
    equivalentHornschuch: '436_2007',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 26,
    favori: getFavori('26'),
    image: `26-Chêne.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne',
    referenceDusage: '205_2090-167',
    referenceUtilisee: '9_205_2090-116700',
    equivalentRenolitPx: '9_205_2390-116700',
    equivalentRenolitPx2: '9_205_2090-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 27,
    favori: getFavori('27'),
    image: `27-Acajou.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'acajou',
    referenceDusage: '206_5021-167',
    referenceUtilisee: '9_206_5321-116700',
    equivalentRenolitPx: '9_206_5321-116700',
    equivalentRenolitPx2: '9_206_5021-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_2001',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 28,
    favori: getFavori('28'),
    image: `28-Acajou.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'acajou',
    referenceDusage: '209_7013-167',
    referenceUtilisee: '9_209_7313-116700',
    equivalentRenolitPx: '9_209_7313-116700',
    equivalentRenolitPx2: '9_209_7013-116700',
    equivalentRenolitFx: '9_209_7713-116701',
    equivalentHornschuch: '436_2002',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 29,
    favori: getFavori('29'),
    image: `29-Oregon 3.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'oregon 3',
    referenceDusage: '211_5008-167',
    referenceUtilisee: '9_211_5008-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_211_5008-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['JAUNE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 30,
    favori: getFavori('30'),
    image: `30-Chêne Gris.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne gris',
    referenceDusage: '214_0005-167',
    referenceUtilisee: '9_214_0305-116700',
    equivalentRenolitPx: '9_214_0305-116700',
    equivalentRenolitPx2: '9_214_0005-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 31,
    favori: getFavori('31'),
    image: `31-Chêne.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne',
    referenceDusage: '214_0006-167',
    referenceUtilisee: '9_214_0006-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_214_0006-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 32,
    favori: getFavori('32'),
    image: `32-Wenge.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'wenge',
    referenceDusage: '214_2001-167',
    referenceUtilisee: '9_214_2001-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_214_2001-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 33,
    favori: getFavori('33'),
    image: `33-Nogal Toscana.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'nogal toscana',
    referenceDusage: '216_7003-167',
    referenceUtilisee: '216_7003-167',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '216_7003-167',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 34,
    favori: getFavori('34'),
    image: `34-Sierra.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'sierra',
    referenceDusage: '216_7009-167',
    referenceUtilisee: '216_7009-167',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '216_7009-167',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 35,
    favori: getFavori('35'),
    image: `35-Nevada.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'nevada',
    referenceDusage: '216_7010-167',
    referenceUtilisee: '216_7010-167',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '216_7010-167',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 36,
    favori: getFavori('36'),
    image: `36-Chêne doré.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne dore',
    referenceDusage: '217_8001-167',
    referenceUtilisee: '9_217_8301-116700',
    equivalentRenolitPx: '9_217_8301-116700',
    equivalentRenolitPx2: '9_217_8001-116700',
    equivalentRenolitFx: '9_217_8701-116701',
    equivalentHornschuch: '436_2076',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 37,
    favori: getFavori('37'),
    image: `37-Noyer.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'noyer',
    referenceDusage: '217_8007-167',
    referenceUtilisee: '9_217_8307-116700',
    equivalentRenolitPx: '9_217_8307-116700',
    equivalentRenolitPx2: '9_217_8007-116700',
    equivalentRenolitFx: '9_217_8707-116701',
    equivalentHornschuch: '436_2048',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 38,
    favori: getFavori('38'),
    image: `38-Amaranth Oak VLF.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'amaranth oak vlf',
    referenceDusage: '3_0078_008-102200',
    referenceUtilisee: '3_0078_008-102200',
    equivalentRenolitPx: '3_0078_008-102200',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 39,
    favori: getFavori('39'),
    image: `39-Truffle Oak VLF.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'truffle oak vlf',
    referenceDusage: '3_0078_009-102200',
    referenceUtilisee: '3_0078_009-102200',
    equivalentRenolitPx: '3_0078_009-102200',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 40,
    favori: getFavori('40'),
    image: `40-Rouge Rubis.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'rouge rubis',
    referenceDusage: '30_0305-167',
    referenceUtilisee: '02_11_31_000011-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_31_000011-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 41,
    favori: getFavori('41'),
    image: `41-Rouge Vin.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'rouge vin',
    referenceDusage: '30_0505-167',
    referenceUtilisee: '02_20_31_000002-116700',
    equivalentRenolitPx: '02_20_31_000002-116700',
    equivalentRenolitPx2: '02_11_31_000012-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 42,
    favori: getFavori('42'),
    image: `42-Black Ulti-Matt.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'black ulti-matt',
    referenceDusage: '30_30_70_00059-001',
    referenceUtilisee: '02_20_01_000002-504700',
    equivalentRenolitPx: '02_20_01_000002-504700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 43,
    favori: getFavori('43'),
    image: `43-Rouge Clair.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'rouge clair',
    referenceDusage: '30_5405-167',
    referenceUtilisee: '02_11_31_000010-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_31_000010-116700',
    equivalentRenolitFx: '02_12_31_000002-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 44,
    favori: getFavori('44'),
    image: `44-Rouge Fonc‚ Lisse.png`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'rouge fonce lisse',
    referenceDusage: '30_8105-083',
    referenceUtilisee: '02_11_31_000013-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_31_000013-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 45,
    favori: getFavori('45'),
    image: `45-Rouge Fonc‚.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'rouge fonce',
    referenceDusage: '30_8105-167',
    referenceUtilisee: '436_5013',
    equivalentRenolitPx: '02_20_31_000002-116700',
    equivalentRenolitPx2: '02_11_31_00013-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5013',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 46,
    favori: getFavori('46'),
    image: `46-Douglas.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'douglas',
    referenceDusage: '306_9037-167',
    referenceUtilisee: '9_306_9037-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_306_9037-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 47,
    favori: getFavori('47'),
    image: `47-Chêne des Montagnes.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne des montagnes',
    referenceDusage: '306_9041-167',
    referenceUtilisee: '9_3069_341-116700',
    equivalentRenolitPx: '9_3069_341-116700',
    equivalentRenolitPx2: '9_3069_041-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 48,
    favori: getFavori('48'),
    image: `48-Chêne Clair.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne clair',
    referenceDusage: '311_8076-168',
    referenceUtilisee: '9_311_8376-116801',
    equivalentRenolitPx: '9_311_8376-116801',
    equivalentRenolitPx2: '9_311_8076-116800',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 49,
    favori: getFavori('49'),
    image: `49-Chêne Rustique.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne rustique',
    referenceDusage: '314_9008-167',
    referenceUtilisee: '426_2012',
    equivalentRenolitPx: '9_314_9308-116700',
    equivalentRenolitPx2: '9_314_9008-116700',
    equivalentRenolitFx: '9_314_9708-116701',
    equivalentHornschuch: '436_2012',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 50,
    favori: getFavori('50'),
    image: `50-Douglas.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'douglas',
    referenceDusage: '315_2009-167',
    referenceUtilisee: '9_315_2309-116700',
    equivalentRenolitPx: '9_315_2309-116700',
    equivalentRenolitPx2: '9_315_2009-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 51,
    favori: getFavori('51'),
    image: `51-Chêne Clair.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne clair',
    referenceDusage: '315_6003-167',
    referenceUtilisee: '9_315_6303-116700',
    equivalentRenolitPx: '9_315_6303-116700',
    equivalentRenolitPx2: '9_315_6303-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 52,
    favori: getFavori('52'),
    image: `52-Macor‚.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'macore',
    referenceDusage: '316_2002-167',
    referenceUtilisee: '9_316_2302-116700',
    equivalentRenolitPx: '9_316_2302-116700',
    equivalentRenolitPx2: '9_316_2002-116700',
    equivalentRenolitFx: '9_316_2702-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 53,
    favori: getFavori('53'),
    image: `53-Chêne.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne',
    referenceDusage: '316_7002-167',
    referenceUtilisee: '9_316_7002-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_316_7002-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 54,
    favori: getFavori('54'),
    image: `54-Mooreiche.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'mooreiche',
    referenceDusage: '316_7004-167',
    referenceUtilisee: '9_316_7304-116700',
    equivalentRenolitPx: '9_316_7304-116700',
    equivalentRenolitPx2: '9_316_7004-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 55,
    favori: getFavori('55'),
    image: `55-Chêne Naturel.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne naturel',
    referenceDusage: '316_7011-167',
    referenceUtilisee: '9_316_7011-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_316_7011-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 56,
    favori: getFavori('56'),
    image: `56-Black Cherry - Merisier.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'black cherry - merisier',
    referenceDusage: '320_2001-167',
    referenceUtilisee: '9_320_2301-116700',
    equivalentRenolitPx: '9_320_2301-116700',
    equivalentRenolitPx2: '9_320_2001-116700',
    equivalentRenolitFx: '9_320_2701-116701',
    equivalentHornschuch: '436_2032',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 57,
    favori: getFavori('57'),
    image: `57-Irish Oak.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'irish oak',
    referenceDusage: '321_1005-148',
    referenceUtilisee: '9_321_1305-114800',
    equivalentRenolitPx: '9_321_1305-114800',
    equivalentRenolitPx2: '9_321_1005-114800',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 58,
    favori: getFavori('58'),
    image: `58-Chêne Irlandais.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne irlandais',
    referenceDusage: '321_1005-167',
    referenceUtilisee: '321_1005-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_321_1005-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 59,
    favori: getFavori('59'),
    image: `59-Merisier.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'merisier',
    referenceDusage: '321_4007-195',
    referenceUtilisee: '9_321_4307-119500',
    equivalentRenolitPx: '9_321_4307-119500',
    equivalentRenolitPx2: '9_321_4007-119500',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 60,
    favori: getFavori('60'),
    image: `60-Cherry Blossom.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'cherry blossom',
    referenceDusage: '321_4008-195',
    referenceUtilisee: '9_321_4308-119500',
    equivalentRenolitPx: '9_321_4308-119500',
    equivalentRenolitPx2: '9_321_4308-119500',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 61,
    favori: getFavori('61'),
    image: `61-Cherry.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'cherry',
    referenceDusage: '321_4009-195',
    referenceUtilisee: '9_321_4009-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_321_4009-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 62,
    favori: getFavori('62'),
    image: `62-Teck de Brunéo AnTEAK.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'teck de bruneo / anteak',
    referenceDusage: '324_1002-195',
    referenceUtilisee: '9_324_1302-119500',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_324_1302-119500',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 63,
    favori: getFavori('63'),
    image: `63-Blanc Veiné.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc veine',
    referenceDusage: '426_1809',
    referenceUtilisee: '426_1809',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '426_1809',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 64,
    favori: getFavori('64'),
    image: `64-Blanc.png`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc',
    referenceDusage: '426_2055',
    referenceUtilisee: '426_2055',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '426_2055',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 65,
    favori: getFavori('65'),
    image: `65-Bleu Provence.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'bleu provence',
    referenceDusage: '426_5019',
    referenceUtilisee: '436_5019',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5019',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 66,
    favori: getFavori('66'),
    image: `66-Vert Amande.png`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'vert amande',
    referenceDusage: '426_5020',
    referenceUtilisee: '436_5020',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5020',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 67,
    favori: getFavori('67'),
    image: `67-Maronbraun.png`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'maronbraun',
    referenceDusage: '426_5025',
    referenceUtilisee: '436_5025',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5025',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 68,
    favori: getFavori('68'),
    image: `68-Tiefschwarz.png`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'tiefschwarz',
    referenceDusage: '426_5135',
    referenceUtilisee: '426_5135',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '426_5135',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['7_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 69,
    favori: getFavori('69'),
    image: `69-Metbrush Aluminium.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'metbrush aluminium',
    referenceDusage: '436_1001',
    referenceUtilisee: '436_1001',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1001',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 70,
    favori: getFavori('70'),
    image: `70-Metbrush Silver.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'metbrush silver',
    referenceDusage: '436_1002',
    referenceUtilisee: '436_1002',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1002',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 71,
    favori: getFavori('71'),
    image: `71-Metbrush Platin.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'metbrush platin',
    referenceDusage: '436_1004',
    referenceUtilisee: '436_1004',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1004',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 72,
    favori: getFavori('72'),
    image: `72-Metbrush Quartzgrau.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'metbrush quartzgrau',
    referenceDusage: '436_1005',
    referenceUtilisee: '436_1005',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1005',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 73,
    favori: getFavori('73'),
    image: `73-Metbrush Anthracite.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'metbrush anthracite',
    referenceDusage: '436_1006',
    referenceUtilisee: '436_1006',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1006',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 74,
    favori: getFavori('74'),
    image: `74-Metbrush Mocca.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'metbrush mocca',
    referenceDusage: '436_1009',
    referenceUtilisee: '436_1009',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1009',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 75,
    favori: getFavori('75'),
    image: `75-Anthracite Alux.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'anthracite alux',
    referenceDusage: '436_1012',
    referenceUtilisee: '436_1012',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1012',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 76,
    favori: getFavori('76'),
    image: `76-Gris DB 703 Alux.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris db 703 alux',
    referenceDusage: '436_1014a',
    referenceUtilisee: '436_1014a',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1014a',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 77,
    favori: getFavori('77'),
    image: `77-Weissaluminium Alux.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'weissaluminium alux',
    referenceDusage: '436_1015',
    referenceUtilisee: '436_1015',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1015',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 78,
    favori: getFavori('78'),
    image: `78-Gris Aluminium Alux.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris aluminium alux',
    referenceDusage: '436_1016',
    referenceUtilisee: '436_1016',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1016',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 79,
    favori: getFavori('79'),
    image: `79-Steingrau Alux.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'steingrau alux',
    referenceDusage: '436_1017',
    referenceUtilisee: '436_1017',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1017',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 80,
    favori: getFavori('80'),
    image: `80-X-Brush Titan - Gris Métal Clair.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'x-brush titan - gris metal clair',
    referenceDusage: '436_1021',
    referenceUtilisee: '436_1021',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1021',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 81,
    favori: getFavori('81'),
    image: `81-Bleu Métal X-Brush.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'bleu metal x-brush',
    referenceDusage: '436_1022',
    referenceUtilisee: '436_1022',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1022',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 82,
    favori: getFavori('82'),
    image: `82-X-Brush Schwarzbraun - Gris Métal Fonc‚.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'x-brush schwarzbraun - gris metal fonce',
    referenceDusage: '436_1023',
    referenceUtilisee: '436_1023',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_1023',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['METALLIQUE'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 83,
    favori: getFavori('83'),
    image: `83-Macoré - Acajou.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'macore - acajou',
    referenceDusage: '436_2001',
    referenceUtilisee: '9_206_5321-116700',
    equivalentRenolitPx: '9_206_5321-116700',
    equivalentRenolitPx2: '9_206_5021-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_2001',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 84,
    favori: getFavori('84'),
    image: `84-Sapeli - Acajou.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'sapeli - acajou',
    referenceDusage: '436_2002',
    referenceUtilisee: '9_209_7313-116700',
    equivalentRenolitPx: '9_209_7313-116700',
    equivalentRenolitPx2: '9_209_7013-116700',
    equivalentRenolitFx: '9_209_7713-116701',
    equivalentHornschuch: '436_2002',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 85,
    favori: getFavori('85'),
    image: `85-Montan Eiche Tabak - Chêne des Marais.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'montan eiche tabak - chêne des marais',
    referenceDusage: '436_2007',
    referenceUtilisee: '436_2007',
    equivalentRenolitPx: '9_205_2389-116700',
    equivalentRenolitPx2: '9_205_2089-116700',
    equivalentRenolitFx: '9_205_2789-116701',
    equivalentHornschuch: '436_2007',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 86,
    favori: getFavori('86'),
    image: `86-Colmar Eiche Spepia - Chêne Rustique.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'colmar eiche sepia - chêne rustique',
    referenceDusage: '436_2012',
    referenceUtilisee: '436_2012',
    equivalentRenolitPx: '9_314_9308-116700',
    equivalentRenolitPx2: '9_314_9008-116700',
    equivalentRenolitFx: '9_314_9708-116701',
    equivalentHornschuch: '436_2012',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 87,
    favori: getFavori('87'),
    image: `87-Krische Piemont - Merisier.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'kirsche piemont - merisier',
    referenceDusage: '436_2032',
    referenceUtilisee: '436_2032',
    equivalentRenolitPx: '9_320_2301-116700',
    equivalentRenolitPx2: '9_320_2001-116700',
    equivalentRenolitFx: '9_320_2701-116701',
    equivalentHornschuch: '436_2032',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 88,
    favori: getFavori('88'),
    image: `88-Staufereiche Mocca - Palissandre.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'staufereiche terra - noyer',
    referenceDusage: '436_2035',
    referenceUtilisee: '436_2035',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_2035',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 89,
    favori: getFavori('89'),
    image: `89-Staufereiche Kolonial - Chêne Dor‚.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'staufereiche kolonial - chêne dore',
    referenceDusage: '436_2036',
    referenceUtilisee: '436_2036',
    equivalentRenolitPx: '9_217_8301-116700',
    equivalentRenolitPx2: '9_217_8001-116700',
    equivalentRenolitFx: '9_217_8701-116701',
    equivalentHornschuch: '436_2036',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 90,
    favori: getFavori('90'),
    image: `90-Staufereiche Mocca Palissandre.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'staufereiche mocca - palissandre',
    referenceDusage: '436_2048',
    referenceUtilisee: '436_2048',
    equivalentRenolitPx: '9_217_8307-116700',
    equivalentRenolitPx2: '9_217_8007-116700',
    equivalentRenolitFx: '9_217_8707-116701',
    equivalentHornschuch: '436_2048',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 91,
    favori: getFavori('91'),
    image: `91-Eiche Mocca.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'eiche mocca',
    referenceDusage: '436_2075',
    referenceUtilisee: '436_2048',
    equivalentRenolitPx: '9_217_8307-116700',
    equivalentRenolitPx2: '9_217_8007-116700',
    equivalentRenolitFx: '9_217_8707-116701',
    equivalentHornschuch: '436_2075',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 92,
    favori: getFavori('92'),
    image: `92-Eiche Kolonial - Chêne Dor‚.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'eiche kolonial - chêne dore',
    referenceDusage: '436_2076',
    referenceUtilisee: '436_2076',
    equivalentRenolitPx: '9_217_8301-116700',
    equivalentRenolitPx2: '9_217_8001-116700',
    equivalentRenolitFx: '9_217_8701-116701',
    equivalentHornschuch: '436_2076',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 93,
    favori: getFavori('93'),
    image: `93-Sapeli.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'sapeli',
    referenceDusage: '436_2085',
    referenceUtilisee: '9_209_7313-116700',
    equivalentRenolitPx: '9_209_7313-116700',
    equivalentRenolitPx2: '9_209_7013-116700',
    equivalentRenolitFx: '9_209_7713-116701',
    equivalentHornschuch: '436_2085',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 94,
    favori: getFavori('94'),
    image: `94-Briké Rosé - Bouleau.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'birke rose - bouleau',
    referenceDusage: '436_3031',
    referenceUtilisee: '436_3031',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3031',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 95,
    favori: getFavori('95'),
    image: `95-Noce Sorrento Natur.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'noce sorrento natur',
    referenceDusage: '436_3041',
    referenceUtilisee: '436_3041',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3041',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 96,
    favori: getFavori('96'),
    image: `96-Noce Sorrento Balsamico.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'noce sorrento balsmico',
    referenceDusage: '436_3042',
    referenceUtilisee: '436_3042',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3042',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 97,
    favori: getFavori('97'),
    image: `97-Cherry Amaretto - Merisier.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'cherry amaretto - merisier',
    referenceDusage: '436_3043',
    referenceUtilisee: '436_3043',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3043',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 98,
    favori: getFavori('98'),
    image: `98-Teak Arte.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'teak arte',
    referenceDusage: '436_3052',
    referenceUtilisee: '436_3052',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3052',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 99,
    favori: getFavori('99'),
    image: `99-Walnuss Amaretto.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'walnuss amaretto',
    referenceDusage: '436_3058',
    referenceUtilisee: '436_3058',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3058',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 100,
    favori: getFavori('100'),
    image: `100-Walnuss Terra - Wenge.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'walnuss terra - wenge',
    referenceDusage: '436_3059',
    referenceUtilisee: '436_3059',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3059',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 101,
    favori: getFavori('101'),
    image: `101-Walnuss Kolonial - Chataignier.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'walnuss kolonial - châtaignier',
    referenceDusage: '436_3062',
    referenceUtilisee: '436_3062',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3062',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 102,
    favori: getFavori('102'),
    image: `102-Walnuss Rehbraun.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'walnuss rehbraun',
    referenceDusage: '436_3063',
    referenceUtilisee: '436_3063',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3063',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 103,
    favori: getFavori('103'),
    image: `103-Newcastle Oak Khaki.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'newcastle oak khaki',
    referenceDusage: '436_3077',
    referenceUtilisee: '436_3077',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3077',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 104,
    favori: getFavori('104'),
    image: `104-Asteiche Natur.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'asteiche natur',
    referenceDusage: '436_3078',
    referenceUtilisee: '436_3078',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3078',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 105,
    favori: getFavori('105'),
    image: `105-Sheffield Oak Grey.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'sheffield oak grey',
    referenceDusage: '436_3086',
    referenceUtilisee: '436_3086',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3086',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 106,
    favori: getFavori('106'),
    image: `106-Sheffield Oak Klonial.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'sheffield oak kolonial',
    referenceDusage: '436_3091',
    referenceUtilisee: '436_3091',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_3091',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 107,
    favori: getFavori('107'),
    image: `107-Anthracite Stylo.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'anthracite stylo',
    referenceDusage: '436_4003a',
    referenceUtilisee: '436_4003a',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_4003a',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 108,
    favori: getFavori('108'),
    image: `108-Gris Quartz Stylo.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris quartz stylo',
    referenceDusage: '436_4047',
    referenceUtilisee: '436_4047',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_4047',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 109,
    favori: getFavori('109'),
    image: `109-Blanc Structuré.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc structure',
    referenceDusage: '436_5001',
    referenceUtilisee: '456_5001',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5001',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 110,
    favori: getFavori('110'),
    image: `110-Gris Anthracite Structuré.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris antracite structure',
    referenceDusage: '436_5003a',
    referenceUtilisee: '436_5003a',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5003a',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 111,
    favori: getFavori('111'),
    image: `111-Bleu Acier.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'bleu acier',
    referenceDusage: '436_5006',
    referenceUtilisee: '436_5006',
    equivalentRenolitPx: '02_20_51_000001-116700',
    equivalentRenolitPx2: '02_11_51_000033-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5006',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 112,
    favori: getFavori('112'),
    image: `112-Brun Noir.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'brun',
    referenceDusage: '436_5010',
    referenceUtilisee: '02_20_81_000010-116700',
    equivalentRenolitPx: '02_20_81_000010-116700',
    equivalentRenolitPx2: '02_11_81_000101-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5010',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 113,
    favori: getFavori('113'),
    image: `113-Rouge Foncé.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'rouge fonce',
    referenceDusage: '436_5013',
    referenceUtilisee: '436_5013',
    equivalentRenolitPx: '02_20_31_000002-116700',
    equivalentRenolitPx2: '02_11_31_00013-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5013',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 114,
    favori: getFavori('114'),
    image: `114-Vert Mousse.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'vert mousse',
    referenceDusage: '436_5014',
    referenceUtilisee: '426_5014',
    equivalentRenolitPx: '02_20_61_000002-116700',
    equivalentRenolitPx2: '02_11_61_000013-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '426_5014',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 115,
    favori: getFavori('115'),
    image: `115-Ivoire.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'ivoire',
    referenceDusage: '436_5015',
    referenceUtilisee: '456_5015',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5015',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 116,
    favori: getFavori('116'),
    image: `116-Vert Sapin.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'vert sapin',
    referenceDusage: '436_5021',
    referenceUtilisee: '436_5021',
    equivalentRenolitPx: '02_20_61_000001-116700',
    equivalentRenolitPx2: '02_11_61_000009-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5021',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 117,
    favori: getFavori('117'),
    image: `117-Seidengrau - Gris Dune.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'seidengrau - gris dune',
    referenceDusage: '436_5031',
    referenceUtilisee: '436_5031',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5031',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 118,
    favori: getFavori('118'),
    image: `118-Kieselgrau.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'kieselgrau',
    referenceDusage: '436_5033',
    referenceUtilisee: '436_5033',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5033',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 119,
    favori: getFavori('119'),
    image: `119-Gris Béton.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris beton',
    referenceDusage: '436_5038',
    referenceUtilisee: '436_5038',
    equivalentRenolitPx: '02_20_71_000019-116700',
    equivalentRenolitPx2: '02_11_71_000057-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5038',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 120,
    favori: getFavori('120'),
    image: `120-Taupe.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'taupe',
    referenceDusage: '436_5043',
    referenceUtilisee: '436_5043',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5043',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 121,
    favori: getFavori('121'),
    image: `121-Steingrau.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'steingrau',
    referenceDusage: '436_5045',
    referenceUtilisee: '436_5045',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5045',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 122,
    favori: getFavori('122'),
    image: `122-Gris Quartz.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris quartz',
    referenceDusage: '436_5047',
    referenceUtilisee: '436_5047',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5047',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 123,
    favori: getFavori('123'),
    image: `123-Gris Argent.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris argent',
    referenceDusage: '436_5049',
    referenceUtilisee: '436_5049',
    equivalentRenolitPx: '02_20_71_000007-116700',
    equivalentRenolitPx2: '02_11_71_000047-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5049',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 124,
    favori: getFavori('124'),
    image: `124-Gris Anthracite SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris anthracite sftn mat',
    referenceDusage: '436_6003a',
    referenceUtilisee: '436_6003a',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6003a',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 125,
    favori: getFavori('125'),
    image: `125-Noir Brun SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'noir brun sftn mat',
    referenceDusage: '436_6010',
    referenceUtilisee: '436_6010',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6010',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 126,
    favori: getFavori('126'),
    image: `126-Pearl SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'pearl sftn mat',
    referenceDusage: '436_6015',
    referenceUtilisee: '456_6015',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_6015',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 127,
    favori: getFavori('127'),
    image: `127-Scharzgrau SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'schwarzgrau sftn mat',
    referenceDusage: '436_6023',
    referenceUtilisee: '436_6023',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6023',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 128,
    favori: getFavori('128'),
    image: `128-Silicia STFN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'silicia sftn mat',
    referenceDusage: '436_6039',
    referenceUtilisee: '436_6039',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6039',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 129,
    favori: getFavori('129'),
    image: `129-Gris Quartz SFTN Mat - Olive.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris quartz sftn mat - olive',
    referenceDusage: '436_6047',
    referenceUtilisee: '436_6047',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6047',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 130,
    favori: getFavori('130'),
    image: `129.3003-Silber Platin.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris basalte sftn mat',
    referenceDusage: '436_6048',
    referenceUtilisee: '436_6048',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6048',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 131,
    favori: getFavori('131'),
    image: `130-Gris Basalte SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 's-bronze sftn mat',
    referenceDusage: '436_6055',
    referenceUtilisee: '436_6055',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6055',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 132,
    favori: getFavori('132'),
    image: `131-S-Bronze SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'fenstergau sftn mat',
    referenceDusage: '436_6066',
    referenceUtilisee: '436_6066',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_6066',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 133,
    favori: getFavori('133'),
    image: `132-Fenstergrau SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris anthracite lisse sftn',
    referenceDusage: '436_7003a',
    referenceUtilisee: '436_7003a',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7003a',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 134,
    favori: getFavori('134'),
    image: `133-Gris Anthracite Lisse SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'brun noir lisse',
    referenceDusage: '436_7010',
    referenceUtilisee: '436_7010',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7010',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 135,
    favori: getFavori('135'),
    image: `134-Brun Noir Lisse.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'schwarzgrau stfn',
    referenceDusage: '436_7023',
    referenceUtilisee: '436_7023',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7023',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 136,
    favori: getFavori('136'),
    image: `135-Schwarzgrau SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'achatgrau sftn',
    referenceDusage: '436_7037',
    referenceUtilisee: '436_7037',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7037',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 137,
    favori: getFavori('137'),
    image: `136-Achatgrau SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris quartz lisse sftn',
    referenceDusage: '436_7047',
    referenceUtilisee: '02_11_71_000046-809700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000046-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7047',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 138,
    favori: getFavori('138'),
    image: `137-Gris Quartz Lisse SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris titanium lisse sftn',
    referenceDusage: '436_7048',
    referenceUtilisee: '436_7048',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000039-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7048',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 139,
    favori: getFavori('139'),
    image: `138-Gris Titanium Lisse SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'gris basalte lisse sftn',
    referenceDusage: '436_7049',
    referenceUtilisee: '02_12_71_000047-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_12_71_000047-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7049',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLORS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 140,
    favori: getFavori('140'),
    image: `139-Gris Basalte Lisse SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'jet black stylo',
    referenceDusage: '446_4062',
    referenceUtilisee: '446_4062',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '446_4062',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLOURS_PLUS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 141,
    favori: getFavori('141'),
    image: `140-Jet Black Stylo.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc veine stylo',
    referenceDusage: '456_4053',
    referenceUtilisee: '456_4053',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_4053',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['SKAI_COOL_COLOURS_PLUS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 142,
    favori: getFavori('142'),
    image: `141-Blanc Veiné Stylo.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'beige crème stylo',
    referenceDusage: '456_4054',
    referenceUtilisee: '456_4054',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_4054',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLOURS_PLUS'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 143,
    favori: getFavori('143'),
    image: `142-Beige Croe Stylo.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'jet black sftn mat',
    referenceDusage: '446_6062',
    referenceUtilisee: '446_6062',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '446_6062',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLOURS_PLUS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 144,
    favori: getFavori('144'),
    image: `143-Jet Black SFTN Mat.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'jet black sftn',
    referenceDusage: '446_7062',
    referenceUtilisee: '446_7062',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '446_7062',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_COOL_COLOURS_PLUS'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 145,
    favori: getFavori('145'),
    image: `144-Jet Black SFTN.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'teck de java - sheffiel oak light',
    referenceDusage: '456_3081',
    referenceUtilisee: '456_3081',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_3081',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 146,
    favori: getFavori('146'),
    image: `145-Teck de Java - Sheffield Oak Light.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc veine',
    referenceDusage: '456_5053',
    referenceUtilisee: '02_20_91_000001-116801',
    equivalentRenolitPx: '02_20_91_000001-116801',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5053',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 147,
    favori: getFavori('147'),
    image: `146-Blanc Veiné.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'beige crème',
    referenceDusage: '456_5054',
    referenceUtilisee: '02_20_11_000001-116700',
    equivalentRenolitPx: '02_20_11_000011-116700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5054',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 148,
    favori: getFavori('148'),
    image: `147-Beige Croe.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'beige',
    referenceDusage: '456_5056',
    referenceUtilisee: '456_5056',
    equivalentRenolitPx: '02_20_11_000006-116700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5056',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 149,
    favori: getFavori('149'),
    image: `148-Beige.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc brillant',
    referenceDusage: '456_5057',
    referenceUtilisee: '456_5057',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5057',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 150,
    favori: getFavori('150'),
    image: `149-Blanc Brillant.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'blanc papyrus',
    referenceDusage: '456_5058',
    referenceUtilisee: '456_5058',
    equivalentRenolitPx: '02_20_91_000003-116700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5058',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_COOL_COLOURS_TP60'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 151,
    favori: getFavori('151'),
    image: `150-Blanc Papyrus.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'windsor',
    referenceDusage: '46_857-100200',
    referenceUtilisee: '02_11_71_000107-100200',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000107-100200',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BEIGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 152,
    favori: getFavori('152'),
    image: `151-Windsor.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'balmoral',
    referenceDusage: '46_858-100200',
    referenceUtilisee: '02_11_71_000106-100200',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000106-100200',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 153,
    favori: getFavori('153'),
    image: `152-Balmoral.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'kensington grey',
    referenceDusage: '46_870-100200',
    referenceUtilisee: '02_11_71_000109-102200',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000109-102200',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 154,
    favori: getFavori('154'),
    image: `153-Kensington Grey.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai woodec turner oak malt',
    referenceDusage: '470_3001',
    referenceUtilisee: '470_3001',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '470_3001',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_WOODEC'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 155,
    favori: getFavori('155'),
    image: `154-Skai Woodec Turner Oak.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai woodec sheffield oak alpine',
    referenceDusage: '470_3002',
    referenceUtilisee: '470_3002',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '470_3002',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_WOODEC'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 156,
    favori: getFavori('156'),
    image: `155-Skai Woodec Sheffield Oak Alpine.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai woodec sheffield oak concrete',
    referenceDusage: '470_3003',
    referenceUtilisee: '470_3003',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '470_3003',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_WOODEC'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 157,
    favori: getFavori('157'),
    image: `156-Skai Woodec Sheffield Oak Concrete.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai woodec turner oak toffee',
    referenceDusage: '470_3004',
    referenceUtilisee: '470_3004',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '470_3004',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_WOODEC'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 158,
    favori: getFavori('158'),
    image: `157-Skai Woodec Turner Oak Tofee.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai mattex fenstergau',
    referenceDusage: '470_6066',
    referenceUtilisee: '470_6066',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '470_6066',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SKAI_MATTEX'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 159,
    favori: getFavori('159'),
    image: `158-Skai Mattex Fenstergrau.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai woodec canterbury oak nature',
    referenceDusage: '470_9028',
    referenceUtilisee: '470_9028',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '470_9028',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['SKAI_WOODEC'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 160,
    favori: getFavori('160'),
    image: `160-Skai Mattex Jet Black.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'skai mattex jet black',
    referenceDusage: '476_6062',
    referenceUtilisee: '476_6062',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '476_6062',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['SKAI_MATTEX'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 161,
    favori: getFavori('161'),
    image: `161-Gris Anthracite Mat.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris anthracite mat',
    referenceDusage: '49_122-801300',
    referenceUtilisee: '02_11_71_000110-801300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000110-801300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 162,
    favori: getFavori('162'),
    image: `162-Gris Argent Mat.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris argent mat',
    referenceDusage: '49_124-801300',
    referenceUtilisee: '02_11_71_000111-801300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000111-801300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 163,
    favori: getFavori('163'),
    image: `163-Chêne Doré - Sherwood G.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'chêne dore - sherwood g',
    referenceDusage: '49_158-101100',
    referenceUtilisee: '3_0029_008-101100',
    equivalentRenolitPx: '3_0029_008-101100',
    equivalentRenolitPx2: '3_0029_005-101100',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 164,
    favori: getFavori('164'),
    image: `164-Canadian.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'canadian',
    referenceDusage: '49_195-011',
    referenceUtilisee: '3_017_8002-101100',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '3_0178_002-101100',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 165,
    favori: getFavori('165'),
    image: `165-Shogun AC.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'shogun ac',
    referenceDusage: '49_197-011',
    referenceUtilisee: '3_017_8001-101100',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '3_017_8001-101100',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 166,
    favori: getFavori('166'),
    image: `166-Shogun AF.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'shogun af',
    referenceDusage: '49_198-011',
    referenceUtilisee: '3_017_8003-101100',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '3_0178_003-101100',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 167,
    favori: getFavori('167'),
    image: `167-Slate Grey Finesse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'slate grey finesse',
    referenceDusage: '49_229-801300',
    referenceUtilisee: '02_11_71_000040-801300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000040-801300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 168,
    favori: getFavori('168'),
    image: `168-Siena PR.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'siena pr',
    referenceDusage: '49_233-015',
    referenceUtilisee: '4_013_1006-114800',
    equivalentRenolitPx: '4_013_1006-114800',
    equivalentRenolitPx2: '4_0131_005-114800',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 169,
    favori: getFavori('169'),
    image: `169-Siena PN.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'siena pn',
    referenceDusage: '49_237-015',
    referenceUtilisee: '4_013_1004-114800',
    equivalentRenolitPx: '4_013_1004-114800',
    equivalentRenolitPx2: '4_0131_003-114800',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 170,
    favori: getFavori('170'),
    image: `170-Winchester.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'winchester',
    referenceDusage: '49_240-015',
    referenceUtilisee: '4_017_5005-114800',
    equivalentRenolitPx: '4_017_5005-114800',
    equivalentRenolitPx2: '4_0175_004-114800',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 171,
    favori: getFavori('171'),
    image: `171-Siena PL.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'siena pl',
    referenceDusage: '49_254-015',
    referenceUtilisee: '4_013_1002-114800',
    equivalentRenolitPx: '4_013_1002-114800',
    equivalentRenolitPx2: '4_0131_001-114800',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['BOIS'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 172,
    favori: getFavori('172'),
    image: `172-Bleu Monument.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu monument',
    referenceDusage: '50_0405-167',
    referenceUtilisee: '02_11_51_000037-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_51_000037-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 173,
    favori: getFavori('173'),
    image: `173-Bleu Brillant.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu brillant',
    referenceDusage: '50_0705-167',
    referenceUtilisee: '02_20_51_000002-116700',
    equivalentRenolitPx: '02_20_51_000002-116700',
    equivalentRenolitPx2: '02_11_51_000028-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 174,
    favori: getFavori('174'),
    image: `174-Bleu Cobalt.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu cobalt',
    referenceDusage: '50_1305-167',
    referenceUtilisee: '02_11_51_000029-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_51_000029-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 175,
    favori: getFavori('175'),
    image: `175-Bleu Atlantique.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu atlantique',
    referenceDusage: '50_3005-167',
    referenceUtilisee: '02_12_51_000004-116701',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_51_000004-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 176,
    favori: getFavori('176'),
    image: `176-Bleu Acier Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu acier lisse',
    referenceDusage: '51_5005-083',
    referenceUtilisee: '02_11_51_000033-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_51_000033-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 177,
    favori: getFavori('177'),
    image: `177-Bleu Acier.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'bleu acier',
    referenceDusage: '51_5005-167',
    referenceUtilisee: '436_5006',
    equivalentRenolitPx: '02_20_51_000001-116700',
    equivalentRenolitPx2: '02_11_51_000033-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5006',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 178,
    favori: getFavori('178'),
    image: `178-Vert Mousse Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'vert mousse lisse',
    referenceDusage: '60_0505-083',
    referenceUtilisee: '02_11_61_0000138-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_61_0000138-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 179,
    favori: getFavori('179'),
    image: `179-Vert Mousse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'vert mousse',
    referenceDusage: '60_0505-167',
    referenceUtilisee: '426_5014',
    equivalentRenolitPx: '02_20_61_000002-116700',
    equivalentRenolitPx2: '02_11_61_000013-116700',
    equivalentRenolitFx: '02_12_61_000001-116701',
    equivalentHornschuch: '426_5014',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['7_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 180,
    favori: getFavori('180'),
    image: `180-Pastel Green.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'pastel grün',
    referenceDusage: '60_2105-167',
    referenceUtilisee: '02_11_61_000020-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_61_000020-116700',
    equivalentRenolitFx: '02_12_61_000005-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 181,
    favori: getFavori('181'),
    image: `181-Vert Tendre.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'vert tendre',
    referenceDusage: '61_1005-167',
    referenceUtilisee: '02_11_61_000014-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_61_000014-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 182,
    favori: getFavori('182'),
    image: `182-Vert Sapin.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'vert sapin',
    referenceDusage: '61_2505-167',
    referenceUtilisee: '436_5021',
    equivalentRenolitPx: '02_20_61_000001-116700',
    equivalentRenolitPx2: '02_11_61_000009-116700',
    equivalentRenolitFx: '02_12_61_000008-116701',
    equivalentHornschuch: '436_5021',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 183,
    favori: getFavori('183'),
    image: `183-Gris Sécurité Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris securite lisse',
    referenceDusage: '70_0405-083',
    referenceUtilisee: '02_20_71_000009-808300',
    equivalentRenolitPx: '02_20_71_000009-808300',
    equivalentRenolitPx2: '02_11_71_000038-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 184,
    favori: getFavori('184'),
    image: `184-Gris Sécurité Veiné.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris securite veine',
    referenceDusage: '70_0405-167',
    referenceUtilisee: '02_11_71_000038-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000038-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 185,
    favori: getFavori('185'),
    image: `185-Gris Basalte Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris basalte lisse',
    referenceDusage: '70_1205-083',
    referenceUtilisee: '436_7048',
    equivalentRenolitPx: '02_20_71_000010-808300',
    equivalentRenolitPx2: '02_11_71_000039-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7048',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 186,
    favori: getFavori('186'),
    image: `186-Gris Basalte.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris basalte',
    referenceDusage: '70_1205-167',
    referenceUtilisee: '02_20_71_000010-116700',
    equivalentRenolitPx: '02_20_71_000010-116700',
    equivalentRenolitPx2: '02_11_71_000039-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 187,
    favori: getFavori('187'),
    image: `187-Schiefergrau Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'schiefergrau lisse',
    referenceDusage: '70_1505-083',
    referenceUtilisee: '02_11_71_000040-809700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000040-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 188,
    favori: getFavori('188'),
    image: `188-Schiefergrau Veiné.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'schiefergrau veine',
    referenceDusage: '70_1505-167',
    referenceUtilisee: '02_11_71_000040-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000040-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 189,
    favori: getFavori('189'),
    image: `189-Gris Anthracite Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris anthracite lisse',
    referenceDusage: '70_1605-083',
    referenceUtilisee: '02_20_71_000001-809700',
    equivalentRenolitPx: '02_20_71_000001-808300',
    equivalentRenolitPx2: '02_11_71_000041-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 190,
    favori: getFavori('190'),
    image: `190-Gris Anthracite.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris anthracite',
    referenceDusage: '70_1605-167',
    referenceUtilisee: '02_20_71_000001-116700',
    equivalentRenolitPx: '02_20_71_000001-116700',
    equivalentRenolitPx2: '02_11_71_000041-116700',
    equivalentRenolitFx: '02_12_71_000003-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 191,
    favori: getFavori('191'),
    image: `191-Anthracite Grey 7016 Smooth 2.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'anthracite grey 7016 smooth 2',
    referenceDusage: '70_1605-8097',
    referenceUtilisee: '02_20_71_000001-809700',
    equivalentRenolitPx: '02_20_71_000001-809700',
    equivalentRenolitPx2: '02_11_71_000041-809700',
    equivalentRenolitFx: '02_12_71_000003-808302',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 192,
    favori: getFavori('192'),
    image: `192-Gris Noir Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris noir lisse',
    referenceDusage: '70_2105-083',
    referenceUtilisee: '02_11_71_000042-809700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000042-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 193,
    favori: getFavori('193'),
    image: `193-Gris Noir Structur‚.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris noir structure',
    referenceDusage: '70_2105-167',
    referenceUtilisee: '02_11_71_000042-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000042-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 194,
    favori: getFavori('194'),
    image: `194-Gris Béton Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris beton lisse',
    referenceDusage: '70_2305-083',
    referenceUtilisee: '02_11_71_000057-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000057-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 195,
    favori: getFavori('195'),
    image: `195-Gris Béton Structuré.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris beton structure',
    referenceDusage: '70_2305-167',
    referenceUtilisee: '02_20_71_000019-116700',
    equivalentRenolitPx: '02_20_71_000019-116700',
    equivalentRenolitPx2: '02_11_71_000057-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_5038',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 196,
    favori: getFavori('196'),
    image: `196-Gris Pierre Vein‚.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris pierre veine',
    referenceDusage: '70_3005-167',
    referenceUtilisee: '02_11_71_000072-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000072-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 197,
    favori: getFavori('197'),
    image: `197-Gris Agathe Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris agathe lisse',
    referenceDusage: '70_3805-083',
    referenceUtilisee: '02_20_71_000005-808300',
    equivalentRenolitPx: '02_20_71_000005-808300',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: undefined,
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 198,
    favori: getFavori('198'),
    image: `198-Gris Agathe Structuré.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris agathe structure',
    referenceDusage: '70_3805-167',
    referenceUtilisee: '02_20_71_000005-116700',
    equivalentRenolitPx: '02_20_71_000005-116700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 199,
    favori: getFavori('199'),
    image: `199-Gris Quartz Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris quartz lisse',
    referenceDusage: '70_3905-083',
    referenceUtilisee: '02_11_71_000046-809700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000046-809700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 200,
    favori: getFavori('200'),
    image: `200-Gris Quartz Structuré.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris quartz structure',
    referenceDusage: '70_3905-167',
    referenceUtilisee: '02_20_71_000012-116700',
    equivalentRenolitPx: '02_20_71_000012-116700',
    equivalentRenolitPx2: '02_11_71_000046-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 201,
    favori: getFavori('201'),
    image: `201-Noir Cendre Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'noir cendre lisse',
    referenceDusage: '71_0595-083',
    referenceUtilisee: '02_12_81_000065-808302',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_81_000065-808302',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 202,
    favori: getFavori('202'),
    image: `202-Gris Argent Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris argent lisse',
    referenceDusage: '71_5505-083',
    referenceUtilisee: '02_12_71_000047-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_12_71_000047-808300',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '436_7049',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 203,
    favori: getFavori('203'),
    image: `203-Gris Argent.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris argent',
    referenceDusage: '71_5505-167',
    referenceUtilisee: '436_5049',
    equivalentRenolitPx: '02_20_71_000007-116700',
    equivalentRenolitPx2: '02_11_71_000047-116700',
    equivalentRenolitFx: '02_12_71_000004-116701',
    equivalentHornschuch: '436_5049',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 204,
    favori: getFavori('204'),
    image: `204-Gris Léger Lisse.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris leger lisse',
    referenceDusage: '72_5105-083',
    referenceUtilisee: '02_11_71_000049-808300',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_71_000049-808300',
    equivalentRenolitFx: '',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 205,
    favori: getFavori('205'),
    image: `205-Gris Léger Structuré.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'gris leger structure',
    referenceDusage: '72_5105-167',
    referenceUtilisee: '02_20_71_000011-116700',
    equivalentRenolitPx: '02_20_71_000011-116700',
    equivalentRenolitPx2: '02_11_71_000049-116700',
    equivalentRenolitFx: '02_12_71_000009-116701',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 206,
    favori: getFavori('206'),
    image: `206-Brun Marron.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'brun marron',
    referenceDusage: '80_9905-167',
    referenceUtilisee: '02_11_81_000120-116700',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_81_000120-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 207,
    favori: getFavori('207'),
    image: `207-Brun Noir.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'brun noir',
    referenceDusage: '85_1805-167',
    referenceUtilisee: '02_20_81_000010-116700',
    equivalentRenolitPx: '02_20_81_000010-116700',
    equivalentRenolitPx2: '02_11_81_000101-116700',
    equivalentRenolitFx: '02_12_81_000066-116701',
    equivalentHornschuch: '436_5010',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 208,
    favori: getFavori('208'),
    image: `208-Marron - Brun Chocolat.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'marron / brun chocolat',
    referenceDusage: '88_7505-167',
    referenceUtilisee: '02_20_81_000018-116700',
    equivalentRenolitPx: '02_20_81_000018-116700',
    equivalentRenolitPx2: '02_11_81_000122-116700',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 209,
    favori: getFavori('209'),
    image: `209-Blanc cérusé.png`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'blanc ceruse',
    referenceDusage: '9_1179_704-116701',
    referenceUtilisee: '9_1179_704-116701',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '9_1179_704-116701',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 210,
    favori: getFavori('210'),
    image: `210-Blanc Papyrus.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'blanc papyrus',
    referenceDusage: '90_1805-167',
    referenceUtilisee: '456_5058',
    equivalentRenolitPx: '02_20_91_000003-116700',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_12_91_000004-116801',
    equivalentHornschuch: '456_5058',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_2'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 211,
    favori: getFavori('211'),
    image: `211-Blanc Veiné FX.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'blanc veine fx',
    referenceDusage: '91_5205-116801fx',
    referenceUtilisee: '02_20_91_000014-116801',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '02_20_91_000014-116801',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['12_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 212,
    favori: getFavori('212'),
    image: `212-Blanc Veiné.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'blanc veine',
    referenceDusage: '91_5205-168',
    referenceUtilisee: '02_20_91_000001-116801',
    equivalentRenolitPx: '02_20_91_000001-116801',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '456_5053',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_1'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 213,
    favori: getFavori('213'),
    image: `213-Vert Monument.jpg`,
    fournisseur: FOURNISSEUR['RENOLIT'],
    nomDuFilm: 'vert monument',
    referenceDusage: '99_2505-167',
    referenceUtilisee: '02_11_01_000001-102200',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '02_11_01_000001-102200',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['PRIX_3'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['EXOFOL'],
    structure: STRUCTURE['STRUCTURE'],
  },
  {
    index: 214,
    favori: getFavori('214'),
    image: `214-Marron Lisse.jpg`,
    fournisseur: FOURNISSEUR['HORNSCHUCH'],
    nomDuFilm: 'marron lisse',
    referenceDusage: 'marron',
    referenceUtilisee: '8014l',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '8014',
    groupePrixCoprodex: undefined,
    garantieFrance: undefined,
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['MARRON'],
    collection: undefined,
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 215,
    favori: getFavori('215'),
    image: `215-Schoco SAF-DB703.png`,
    fournisseur: FOURNISSEUR['SCHU_CO_AUTOMOTIV'],
    nomDuFilm: 'schüco saf-db 703',
    referenceDusage: 'saf-db 703 matt',
    referenceUtilisee: 'saf-db 703 matt',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SCHU_CO_AUTOMOTIV'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 216,
    favori: getFavori('216'),
    image: `216-Schoco SAF-RAL 9006 semigloss.png`,
    fournisseur: FOURNISSEUR['SCHU_CO_AUTOMOTIV'],
    nomDuFilm: 'schüco saf-ral 9006 semigloss',
    referenceDusage: 'saf-ral 9006 semigloss',
    referenceUtilisee: 'saf-ral 9006 semigloss',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SCHU_CO_AUTOMOTIV'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 217,
    favori: getFavori('217'),
    image: `217-Schoco SAF-RAL 9007 semigloss.png`,
    fournisseur: FOURNISSEUR['SCHU_CO_AUTOMOTIV'],
    nomDuFilm: 'schüco saf-ral 9007 semigloss',
    referenceDusage: 'saf-ral 9007 semigloss',
    referenceUtilisee: 'saf-ral 9007 semigloss',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_BRILLANT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['SCHU_CO_AUTOMOTIV'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 218,
    favori: getFavori('218'),
    image: `218-Veka Spectral 7016-Anthracite.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 7016-anthracite',
    referenceDusage: '7016-anthracite',
    referenceUtilisee: '7016-anthracite',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 219,
    favori: getFavori('219'),
    image: `219-Veka Spectral 9011-Graphite.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 9011-graphite',
    referenceDusage: '9011-graphite',
    referenceUtilisee: '9011-graphite',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['10_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['NOIR'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 220,
    favori: getFavori('220'),
    image: `220-Veka Spectral C34-Umbra.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral c34-umbra',
    referenceDusage: 'c34-umbra',
    referenceUtilisee: 'c34-umbra',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['MARRON'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 221,
    favori: getFavori('221'),
    image: `221-Veka Spectral 9010-Blanc Pur.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 9010-blanc pur',
    referenceDusage: '9010-blanc pur',
    referenceUtilisee: '9010-blanc pur',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 222,
    favori: getFavori('222'),
    image: `222-Veka Spectral 7040-Gris Fenêtre.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 7040-gris fenêtre',
    referenceDusage: '7040-gris fenêtre',
    referenceUtilisee: '7040-gris fenêtre',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['GRIS'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 223,
    favori: getFavori('223'),
    image: `223-Veka Spectral 5011-Bleu Acier.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 5011-bleu acier',
    referenceDusage: '5011-bleu acier',
    referenceUtilisee: '5011-bleu acier',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['BLEU'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 224,
    favori: getFavori('224'),
    image: `224-Veka Spectral 6009-Vert Sapin.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 6009-vert sapin',
    referenceDusage: '6099-vert sapin',
    referenceUtilisee: '6099-vert sapin',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 225,
    favori: getFavori('225'),
    image: `225-Veka Spectral 6012-Vert Noir.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 6012-vert noir',
    referenceDusage: '6012-vert noir',
    referenceUtilisee: '6012-vert noir',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['VERT'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 226,
    favori: getFavori('226'),
    image: `226-Veka Spectral 9016-Blanc Veka.jpg`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 9016-blanc veka',
    referenceDusage: '9016-blanc veka',
    referenceUtilisee: '9016-blanc veka',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['BLANC'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
  {
    index: 227,
    favori: getFavori('227'),
    image: `227-Veka Spectral 3005-Bordeaux.png`,
    fournisseur: FOURNISSEUR['VEKA_SPECTRAL'],
    nomDuFilm: 'veka spectral 3005-bordeaux',
    referenceDusage: '3005-bordeaux',
    referenceUtilisee: '3005-bordeaux',
    equivalentRenolitPx: '/',
    equivalentRenolitPx2: '/',
    equivalentRenolitFx: '/',
    equivalentHornschuch: '/',
    groupePrixCoprodex: GROUPE_PRIX_COPRODEX['TARIF_SPECIAL'],
    garantieFrance: GARANTIE_FRANCE['7_5_ANS'],
    typeDeFilm: TYPE_DE_FILM['UNI_MAT'],
    nuance: NUANCE['ROUGE'],
    collection: COLLECTION['VEKA_SPECTRAL'],
    structure: STRUCTURE['LISSE'],
  },
]
