export enum FOURNISSEUR {
  'RENOLIT' = 'Rénolit',
  'HORNSCHUCH' = 'Hornschuch',
  'SCHU_CO_AUTOMOTIV' = 'Schü Co Automotiv',
  'VEKA_SPECTRAL' = 'Veka Spectral',
}
export enum GROUPE_PRIX_COPRODEX {
  'PRIX_1' = 'Prix 1',
  'PRIX_2' = 'Prix 2',
  'PRIX_3' = 'Prix 3',
  'TARIF_SPECIAL' = 'Tarif Spécial',
}
export enum GARANTIE_FRANCE {
  '5_ANS' = '5 Ans',
  '7_ANS' = '7 Ans',
  '7_5_ANS' = '7,5 Ans',
  '10_ANS' = '10 Ans',
  '12_5_ANS' = '12,5 Ans',
}
export enum TYPE_DE_FILM {
  'UNI_BRILLANT' = 'Uni Brillant',
  'METALLIQUE' = 'Métallique',
  'UNI_MAT' = 'Uni Mat',
  'BOIS' = 'Bois',
}
export enum NUANCE {
  'BEIGE' = 'Beige',
  'BLEU' = 'Bleu',
  'VERT' = 'Vert',
  'JAUNE' = 'Jaune',
  'GRIS' = 'Gris',
  'MARRON' = 'Marron',
  'BLANC' = 'Blanc',
  'NOIR' = 'Noir',
  'ROUGE' = 'Rouge',
}
export enum COLLECTION {
  'EXOFOL' = 'Exofol',
  'SKAI_COOL_COLORS' = 'Skai Cool Colors',
  'SKAI_COOL_COLOURS_TP60' = 'Skai Cool Colours TP60',
  'SKAI_COOL_COLOURS_PLUS' = 'Skai Cool Colours Plus',
  'SKAI_WOODEC' = 'Skai Woodec',
  'SKAI_MATTEX' = 'Skai Mattex',
  'SCHU_CO_AUTOMOTIV' = 'Schü Co Automotiv',
  'VEKA_SPECTRAL' = 'Veka Spectral',
}
export enum STRUCTURE {
  'STRUCTURE' = 'Structuré',
  'LISSE' = 'Lissé',
}
export interface Teinte {
  index: number
  image: string
  fournisseur: FOURNISSEUR
  nomDuFilm: string
  referenceDusage: string
  referenceUtilisee: string
  equivalentRenolitPx: string
  equivalentRenolitPx2: string
  equivalentRenolitFx: string
  equivalentHornschuch: string
  groupePrixCoprodex?: GROUPE_PRIX_COPRODEX
  garantieFrance?: GARANTIE_FRANCE
  typeDeFilm?: TYPE_DE_FILM
  nuance?: NUANCE
  collection?: COLLECTION
  structure?: STRUCTURE
  favori?: boolean
}
