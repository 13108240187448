
import { DigInputCheckbox } from '@digithia/input/checkbox'
import { defineComponent, PropType, SetupContext } from 'vue'
import { TeinteForm } from '../model/Form'
import {
  COLLECTION,
  FOURNISSEUR,
  GARANTIE_FRANCE,
  GROUPE_PRIX_COPRODEX,
  NUANCE,
  STRUCTURE,
  TYPE_DE_FILM,
} from '../model/Teinte'

export default defineComponent({
  components: {},
  props: {
    teinte: {
      type: Object as PropType<TeinteForm>,
      required: true,
    },
  },
  emit: ['update', 'reinit', 'reinitFav'],
  setup(props: any, context: SetupContext) {
    const update = (field: keyof TeinteForm, value: any) => {
      context.emit('update', { field, value })
    }
    const reinit = () => {
      const favCheckbox = document.querySelector(
        '#fav-checkbox',
      ) as DigInputCheckbox
      favCheckbox.uncheck()
      context.emit('reinit')
    }
    const reinitFav = () => {
      context.emit('reinitFav')
    }

    return {
      update,
      reinit,
      reinitFav,
      COLLECTION,
      FOURNISSEUR,
      GARANTIE_FRANCE,
      GROUPE_PRIX_COPRODEX,
      NUANCE,
      STRUCTURE,
      TYPE_DE_FILM,
    }
  },
})
