export const getImgUrl = (pic: string): string => {
  if (!pic) return ''
  return require(`@/assets/${pic}`)
}

export const addFavori = (id: string): void => {
  localStorage.setItem(id, 'true')
}
export const removeFavori = (id: string): void => {
  localStorage.setItem(id, '')
}
export const getFavori = (id: string): boolean => {
  return !!localStorage.getItem(id)
}
