import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fb1ffcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
const _hoisted_16 = ["value"]
const _hoisted_17 = ["checked"]
const _hoisted_18 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dig-input-text", {
      value: _ctx.teinte.global,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('global', $event.target.value)))
    }, " Rechercher ", 40, _hoisted_2),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.collection,
      onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('collection', $event.target.value))),
      label: "Choix de la collection"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.COLLECTION, (collection) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: collection,
          value: collection
        }, _toDisplayString(collection), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_3),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.fournisseur,
      onSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update('fournisseur', $event.target.value))),
      label: "Choix du fournisseur"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.FOURNISSEUR, (fournisseur) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: fournisseur,
          value: fournisseur
        }, _toDisplayString(fournisseur), 9, _hoisted_6))
      }), 128))
    ], 40, _hoisted_5),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.garantieFrance,
      onSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update('garantieFrance', $event.target.value))),
      label: "Choix de la garantie"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.GARANTIE_FRANCE, (garantieFrance) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: garantieFrance,
          value: garantieFrance
        }, _toDisplayString(garantieFrance), 9, _hoisted_8))
      }), 128))
    ], 40, _hoisted_7),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.groupePrixCoprodex,
      onSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.update('groupePrixCoprodex', $event.target.value))),
      label: "Choix du groupe de prix"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.GROUPE_PRIX_COPRODEX, (groupePrixCoprodex) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: groupePrixCoprodex,
          value: groupePrixCoprodex
        }, _toDisplayString(groupePrixCoprodex), 9, _hoisted_10))
      }), 128))
    ], 40, _hoisted_9),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.nuance,
      onSelect: _cache[5] || (_cache[5] = ($event: any) => (_ctx.update('nuance', $event.target.value))),
      label: "Choix de la nuance"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.NUANCE, (nuance) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: nuance,
          value: nuance
        }, _toDisplayString(nuance), 9, _hoisted_12))
      }), 128))
    ], 40, _hoisted_11),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.structure,
      onSelect: _cache[6] || (_cache[6] = ($event: any) => (_ctx.update('structure', $event.target.value))),
      label: "Choix de la structure"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.STRUCTURE, (structure) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: structure,
          value: structure
        }, _toDisplayString(structure), 9, _hoisted_14))
      }), 128))
    ], 40, _hoisted_13),
    _createElementVNode("dig-input-select-picker", {
      value: _ctx.teinte.typeDeFilm,
      onSelect: _cache[7] || (_cache[7] = ($event: any) => (_ctx.update('typeDeFilm', $event.target.value))),
      label: "Choix du type de film"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.TYPE_DE_FILM, (typeDeFilm) => {
        return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
          key: typeDeFilm,
          value: typeDeFilm
        }, _toDisplayString(typeDeFilm), 9, _hoisted_16))
      }), 128))
    ], 40, _hoisted_15),
    _createElementVNode("dig-input-checkbox", {
      id: "fav-checkbox",
      checked: _ctx.teinte.favori,
      onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.update('favori', $event.detail)))
    }, " Favoris ", 40, _hoisted_17),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("dig-ui-standard-button", {
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.reinit()))
      }, " Remise à 0 du formulaire "),
      _createElementVNode("dig-ui-standard-button", {
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.reinitFav()))
      }, " Remise à 0 des favoris ")
    ])
  ]))
}