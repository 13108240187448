
import { defineComponent, Ref, ref } from 'vue'
import TableTeinte from './components/TableTeinte.vue'
import SearchForm from './components/SearchForm.vue'
import TeinteDetails from './components/TeinteDetails.vue'
import { ALL_TEINTES } from './model/Teintes'
import { defaultForm, TeinteForm, search } from './model/Form'
import { Teinte } from './model/Teinte'
import { removeFavori } from './model/store'

export default defineComponent({
  name: 'App',
  components: {
    TeinteDetails,
    TableTeinte,
    SearchForm,
  },
  setup() {
    const teintes = ref(ALL_TEINTES)
    const searchTeintes = ref(ALL_TEINTES)
    const searchForm = ref({ ...defaultForm })
    const selectedTeinte: Ref<Teinte | undefined> = ref(undefined)

    const update = (event: { field: keyof TeinteForm; value: never }) => {
      searchForm.value[event.field] = event.value
      searchTeintes.value = search(teintes.value, searchForm.value)
    }

    const reinit = () => {
      searchForm.value = { ...defaultForm }
      searchTeintes.value = search(teintes.value, searchForm.value)
    }

    const updateTeintes = ({
      index,
      key,
      value,
    }: {
      index: number
      key: keyof Teinte
      value: any
    }) => {
      teintes.value = teintes.value.map((t) =>
        t.index === index ? { ...t, [key]: value } : t,
      )
      searchTeintes.value = search(teintes.value, searchForm.value)
    }

    const reinitFav = () => {
      for (const teinte of teintes.value) {
        removeFavori(`${teinte.index}`)
      }
      searchTeintes.value = search(teintes.value, searchForm.value)
    }

    const selectTeinte = (id: number | undefined) => {
      selectedTeinte.value = teintes.value.find((t) => t.index === id)
    }

    return {
      searchForm,
      teintes,
      searchTeintes,
      selectedTeinte,
      selectTeinte,
      updateTeintes,
      update,
      reinit,
      reinitFav,
    }
  },
})
