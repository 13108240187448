
import { defineComponent, PropType } from 'vue'
import { addFavori, getFavori, getImgUrl, removeFavori } from '../model/store'
import { Teinte } from '../model/Teinte'

export default defineComponent({
  emits: ['open', 'update'],
  props: {
    teinte: {
      type: Object as PropType<Teinte>,
      required: true,
    },
  },
  setup(props: Readonly<{ teinte: Teinte }>, context: any) {
    const toggleFavori = (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (getFavori('' + props.teinte.index)) {
        removeFavori('' + props.teinte.index)
      } else {
        addFavori('' + props.teinte.index)
      }
      context.emit('update', {
        index: props.teinte.index,
        key: 'favori',
        value: getFavori('' + props.teinte.index),
      })
    }

    const open = () => {
      context.emit('open')
    }

    return {
      toggleFavori,
      getImgUrl,
      open,
    }
  },
})
