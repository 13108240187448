import { getFavori } from './store'
import {
  COLLECTION,
  FOURNISSEUR,
  GARANTIE_FRANCE,
  GROUPE_PRIX_COPRODEX,
  NUANCE,
  STRUCTURE,
  Teinte,
  TYPE_DE_FILM,
} from './Teinte'

export interface TeinteForm {
  global: string
  favori?: boolean
  fournisseur?: FOURNISSEUR
  nomDuFilm?: string
  referenceDusage?: string
  referenceUtilisee?: string
  equivalentRenolitPx?: string
  equivalentRenolitPx2?: string
  equivalentRenolitFx?: string
  equivalentHornschuch?: string
  groupePrixCoprodex?: GROUPE_PRIX_COPRODEX
  garantieFrance?: GARANTIE_FRANCE
  typeDeFilm?: TYPE_DE_FILM
  nuance?: NUANCE
  collection?: COLLECTION
  structure?: STRUCTURE
}

export const defaultForm: TeinteForm = {
  global: '',
  nomDuFilm: '',
  favori: false,
}

export const search = (teintes: Teinte[], teinteForm: TeinteForm): Teinte[] => {
  return teintes.filter((t) => {
    const nomDuFilm = t.nomDuFilm?.toLocaleLowerCase()
    const equivalentHornschuch = t.equivalentHornschuch?.toLocaleLowerCase()
    const equivalentRenolitFx = t.equivalentRenolitFx?.toLocaleLowerCase()
    const equivalentRenolitPx = t.equivalentRenolitPx?.toLocaleLowerCase()
    const equivalentRenolitPx2 = t.equivalentRenolitPx2?.toLocaleLowerCase()
    const referenceDusage = t.referenceDusage?.toLocaleLowerCase()
    const referenceUtilisee = t.referenceUtilisee?.toLocaleLowerCase()

    const globalSearch = teinteForm.global.toLowerCase()

    return (
      (nomDuFilm.match(globalSearch) ||
        equivalentHornschuch.match(globalSearch) ||
        equivalentRenolitFx.match(globalSearch) ||
        equivalentRenolitPx.match(globalSearch) ||
        equivalentRenolitPx2.match(globalSearch) ||
        referenceDusage.match(globalSearch) ||
        referenceUtilisee.match(globalSearch)) &&
      (!teinteForm.favori || getFavori('' + t.index)) &&
      (!teinteForm.collection || teinteForm.collection === t.collection) &&
      (!teinteForm.fournisseur || teinteForm.fournisseur === t.fournisseur) &&
      (!teinteForm.garantieFrance ||
        teinteForm.garantieFrance === t.garantieFrance) &&
      (!teinteForm.groupePrixCoprodex ||
        teinteForm.groupePrixCoprodex === t.groupePrixCoprodex) &&
      (!teinteForm.nuance || teinteForm.nuance === t.nuance) &&
      (!teinteForm.structure || teinteForm.structure === t.structure) &&
      (!teinteForm.typeDeFilm || teinteForm.typeDeFilm === t.typeDeFilm)
    )
  })
}
