
import { defineComponent, PropType } from 'vue'
import TeinteCell from './TeinteCell.vue'
import { Teinte } from '../model/Teinte'
import { addFavori, getFavori, removeFavori } from '../model/store'

export default defineComponent({
  components: {
    TeinteCell,
  },
  props: {
    teintes: {
      type: Object as PropType<Teinte[]>,
      required: true,
    },
  },
  emits: ['select', 'update'],
  setup(props: Readonly<{ teintes: Teinte[] }>, context: any) {
    const select = (index: number) => {
      context.emit('select', index)
    }
    const update = ({
      index,
      key,
      value,
    }: {
      index: number
      key: keyof Teinte
      value: any
    }) => {
      context.emit('update', { key, value, index })
    }

    return { select, getFavori, update }
  },
})
