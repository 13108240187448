import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "no-result"
}
const _hoisted_2 = { class: "table-teinte" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_teinte_cell = _resolveComponent("teinte-cell")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.teintes.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Aucun résultat ne correspond à la recherche "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teintes, (teinte) => {
        return (_openBlock(), _createBlock(_component_teinte_cell, {
          class: "table-cell",
          key: teinte.index,
          teinte: teinte,
          onOpen: ($event: any) => (_ctx.select(teinte.index)),
          onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update($event)))
        }, null, 8, ["teinte", "onOpen"]))
      }), 128))
    ])
  ], 64))
}