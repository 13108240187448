
import { defineComponent, PropType } from 'vue'
import { getImgUrl } from '../model/store'
import { Teinte } from '../model/Teinte'

export default defineComponent({
  props: {
    teinte: {
      type: Object as PropType<Teinte>,
      required: false,
    },
  },
  emits: ['close'],
  setup(props: any, context: any) {
    const close = () => {
      context.emit('close')
    }
    return { close, getImgUrl }
  },
})
