import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-buttons" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "teinte-display",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("dig-ui-icon", {
        class: _normalizeClass(["favori", { active: _ctx.teinte.favori }]),
        type: 'heart',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleFavori($event)))
      }, null, 2),
      _createElementVNode("dig-ui-icon", {
        type: 'show',
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open()))
      })
    ]),
    _createElementVNode("img", {
      src: _ctx.getImgUrl('teintes/' + _ctx.teinte.image)
    }, null, 8, _hoisted_2)
  ]))
}