import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "main-table" }
const _hoisted_3 = { class: "main-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_teinte_details = _resolveComponent("teinte-details")!
  const _component_table_teinte = _resolveComponent("table-teinte")!
  const _component_search_form = _resolveComponent("search-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_teinte_details, {
        teinte: _ctx.selectedTeinte,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTeinte(undefined)))
      }, null, 8, ["teinte"]),
      _createVNode(_component_table_teinte, {
        teintes: _ctx.searchTeintes,
        onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTeinte($event))),
        onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateTeintes($event)))
      }, null, 8, ["teintes"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_search_form, {
        teinte: _ctx.searchForm,
        onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update($event))),
        onReinit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reinit())),
        onReinitFav: _cache[5] || (_cache[5] = ($event: any) => (_ctx.reinitFav()))
      }, null, 8, ["teinte"])
    ])
  ]))
}